import React from "react";
import "./Skills.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Console from "../Console/Console";
import Particles from "react-tsparticles";
import Slider from "react-slick";
export default function Skills() {
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="Skills">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.1,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#c00329",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.1,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 2000,
              },
              value: 100,
            },
            opacity: {
              value: 0.2,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
      <Header />
      <Console name="Skills" />
      <div className="Skills_Titles">
        <h1 className="Skills_Title">Skills</h1>
      </div>
      <div className="Skills_hero">
        <img
          className="Skills_hero_img"
          src="../../../../Images/abstractImg.svg"
          alt=" Abstract Background"
        />
      </div>
      <div className="Skills_section">
        <Slider {...settings}>
          <div>
            <div className="Skills_container">
              <h4>Database</h4>
              <div className="icon__container">
                <div>
                  {" "}
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/mySql.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/postgres.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div>
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/mongo.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/oracleDB.svg"
                    alt=" Abstract Background"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="Skills_container">
              <h4>Languages</h4>
              <div className="icon__container">
                <div>
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/java.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/bash.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div>
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/php.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/python.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div>
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/js.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/html5.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div>
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/css3.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/sass.svg"
                    alt=" Abstract Background"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="Skills_container">
              <h4>Frameworks & Tools</h4>
              <div className="icon__container">
                <div>
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/git.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/postman.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div>
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/react.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/redux.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div>
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/material-ui.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Skills__iconImg"
                    src="../../../../Images/redux.svg"
                    alt=" Abstract Background"
                  />
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <Footer />
    </div>
  );
}
