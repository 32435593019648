import React from "react";
import "./Contact.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Console from "../Console/Console";
import Particles from "react-tsparticles";
export default function Contact() {
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <div className="Contact">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.1,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#c00329",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.1,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 2000,
              },
              value: 100,
            },
            opacity: {
              value: 0.2,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
      <Header />
      <Console name="Contact" />
      <div className="Contact_Titles">
        <h1 className="Contact_Title">Contact</h1>
      </div>
      <div className="Contact_hero">
        <img
          className="Contact_hero_img"
          src="../../../../Images/abstract2.svg"
          alt=" Abstract Background"
        />
      </div>
      <div className="Contact_Form">
        <div>
          <h4>Social Links & Contact Details</h4>
          <p>
            You can contact me through these links, I would be happy to answer
            any questions and meet with you.
          </p>
        </div>
        <div className="icon__container">
          <a
            href="https://www.linkedin.com/in/nkby/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="About__iconImg"
              src="../../../../Images/linkd.svg"
              alt=" Abstract Background"
            />
          </a>
          <a href="mailto:nbefkadu@gmail.com" target="_blank" rel="noreferrer">
            <img
              className="About__iconImg"
              src="../../../../Images/gmail.svg"
              alt=" Abstract Background"
            />
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
