import React, { useState, useEffect } from "react";
import "./Footer.css";

export default function Footer() {
  const [daytime, setDaytime] = useState(new Date().toLocaleString());
  useEffect(() => {
    let secTimer = setInterval(() => {
      setDaytime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(secTimer);
  }, []);

  return (
    <div className="Footer">
      <div className="Footer_container">
        <div className="Footer_right">
          <p>V5.01</p>
        </div>
        <div className="Footer_left">
          <div className="location">
            <p>AGENT COORDINATE: 35.294952 : -93.998251</p>
          </div>
          <div className="time">
            <p>{daytime}</p>
          </div>
          <div className="date">
            <p>01.01.2077</p>
          </div>
        </div>
      </div>
    </div>
  );
}
