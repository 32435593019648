import React from "react";
import "./Projects.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Console from "../Console/Console";
import Particles from "react-tsparticles";
import Slider from "react-slick";
export default function Projects() {
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="Project">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.1,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#c00329",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.1,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 2000,
              },
              value: 100,
            },
            opacity: {
              value: 0.2,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
      <Header />
      <Console name="Project" />
      <div className="Project_Titles">
        <h1 className="Project_Title">Project</h1>
      </div>
      <div className="Project_projects">
        <Slider {...settings}>
          <div>
            <div className="Project_containers">
              <img
                src="../../../../Images/net.svg"
                className="Project__Img"
                alt="project picture"
              />
              <div className="Project_Information">
                <div className="title">
                  <h4>Netflix Clone</h4>
                </div>
                <div className="description">
                  <p>
                    A clone of the Netflix web app, made through themovieDB API
                    to populate the movie data and Stripe API for payment
                    functionality. The application also allows for login and
                    authentication made possible through firebase.
                  </p>
                </div>
                <div className="icons">
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/git.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/firebase.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/html5.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/css3.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/js.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/react.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/redux.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div className="buttons">
                  <a
                    href="https://nemo-video-service.web.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Site</span>
                  </a>
                  <a
                    href="https://github.com/NahomBefekadu/Netflix-clone"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Source</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="Project_containers">
              <img
                src="../../../../Images/spaceStore.svg"
                className="Project__Img"
                alt="project picture"
              />
              <div className="Project_Information">
                <div className="title">
                  <h4>E-Commerce Store</h4>
                </div>
                <div className="description">
                  <p>
                    An E-commerce platform on Orion Star where you can buy the
                    latest ships to further your space exploration. Based on
                    Amazon's E-commerce web platform.
                  </p>
                </div>
                <div className="icons">
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/git.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/firebase.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/html5.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/css3.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/js.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/react.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/redux.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div className="buttons">
                  <a
                    href="https://social-app-twitter-clone.web.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Site</span>
                  </a>
                  <a
                    href="https://github.com/NahomBefekadu/orionSpaceStore"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Source</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="Project_containers">
              <img
                src="../../../../Images/backend2.svg"
                className="Project__Img"
                alt="project picture"
              />
              <div className="Project_Information">
                <div className="title">
                  <h4>Java Spring Boot Backend</h4>
                </div>
                <div className="description">
                  <p>
                    Created a backend for a simple Twitter-like application
                    using enterprise programming and deployment tools.
                    Implemented and tested OAuth 2.0 Authorization Code Grant
                    Flow and applied MVC pattern to the application. Used Java
                    spring-boot and Integrated various Java-based technologies
                    in the enterprise environment.
                  </p>
                </div>
                <div className="icons">
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/git.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/java.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/postman.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/mySql.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div className="buttons">
                  <a
                    href="https://github.com/NahomBefekadu/spring-boot-simple-backend"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Source</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="Project_containers">
              <img
                src="../../../../Images/backend.svg"
                className="Project__Img"
                alt="project picture"
              />
              <div className="Project_Information">
                <div className="title">
                  <h4>NBK CORP</h4>
                </div>
                <div className="description">
                  <p>
                    Designed a simple Back-End Platform that has endpoints for a
                    simple E-commerce website with the functionality to create
                    users, CRUD operations on Products, and more.
                  </p>
                </div>
                <div className="icons">
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/git.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/js.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/postman.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/postgres.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div className="buttons">
                  <a
                    href="https://github.com/NahomBefekadu/NBKCorp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Source</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="Project_containers">
              <img
                src="../../../../Images/web3.svg"
                className="Project__Img"
                alt="project picture"
              />
              <div className="Project_Information">
                <div className="title">
                  <h4>Portfolio Version 3</h4>
                </div>
                <div className="description">
                  <p>
                    The third iteration of the portfolio, using a simple
                    approach to design a portfolio. The wireframe of the
                    portfolio was made in Figma, and developed.
                  </p>
                </div>
                <div className="icons">
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/git.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/html5.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/css3.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/sass.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/js.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div className="buttons">
                  <a
                    href="https://github.com/NahomBefekadu/portfoliov3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Source</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="Project_containers">
              <img
                src="../../../../Images/spotify.svg"
                className="Project__Img"
                alt="project picture"
              />
              <div className="Project_Information">
                <div className="title">
                  <h4>Spotify Playlist</h4>
                </div>
                <div className="description">
                  <p>
                    Spotify web app can search for songs and curate your
                    playlists using Spotify's API. The web app can also save the
                    playlists made onto your account.
                  </p>
                </div>
                <div className="icons">
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/git.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/html5.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/css3.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/js.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/react.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div className="buttons">
                  <a
                    href="https://github.com/NahomBefekadu/spotify"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Source</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="Project_containers">
              <img
                src="../../../../Images/web.svg"
                className="Project__Img"
                alt="project picture"
              />
              <div className="Project_Information">
                <div className="title">
                  <h4>Portfolio version 1</h4>
                </div>
                <div className="description">
                  <p>
                    Created a web portfolio of my work and accomplishments.
                    Implemented different design methodologies and styles to
                    complete multiple versions. Produced a responsive website
                    using JavaScript and HTML elements and other CSS elements.
                  </p>
                </div>
                <div className="icons">
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/git.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/html5.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/css3.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/js.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div className="buttons">
                  <a
                    href="https://github.com/NahomBefekadu/PortoflioV1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Source</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="Project_containers">
              <img
                src="../../../../Images/web4.svg"
                className="Project__Img"
                alt="project picture"
              />
              <div className="Project_Information">
                <div className="title">
                  <h4>Portfolio v4</h4>
                </div>
                <div className="description">
                  <p>
                    The last iteration of my portfolio, it's the oldest version
                    of the current website. Created using react and designed on
                    Figma.
                  </p>
                </div>
                <div className="icons">
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/git.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/firebase.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/html5.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/css3.svg"
                    alt=" Abstract Background"
                  />{" "}
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/js.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/react.svg"
                    alt=" Abstract Background"
                  />
                  <img
                    className="Project__iconImg"
                    src="../../../../Images/redux.svg"
                    alt=" Abstract Background"
                  />
                </div>
                <div className="buttons">
                  <a
                    href="https://portfolio-98129.web.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Site</span>
                  </a>
                  <a
                    href="https://github.com/NahomBefekadu/portfoliov4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="Project__butnText">Go to Source</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className="Project_hero2">
        <img
          className="Project_hero_img2"
          src="../../../../Images/abstract3.svg"
          alt=" Abstract Background"
        />
      </div>
      <Footer />
    </div>
  );
}
