import React from "react";
import "./Console.css";
export default function Console({ name }) {
  return (
    <div className="Console">
      <div className="console__typing">
        <p>Authenticating credentials .... Confirmed</p>
        <p>Nahom_Server 96 connection....... Established</p>
        <p>Reading Package .... Done</p>
        <p>Protocol 1z590 ...Activated </p>
        <p>Accessing File /Nahom/{name} .... Done</p>
        <p>Program --- {name} Page</p>
      </div>
    </div>
  );
}
