import React from "react";
import "./Login.css";
import { Link } from "react-router-dom";
export default function Login() {
  return (
    <div className="Login">
      <div className="login__container">
        <video
          autoPlay="autoPlay"
          muted
          loop={true}
          id="myVideo"
          className="login__video"
        >
          <source
            src="../../../../Videos/loginScreen.webm"
            type="video/webm"
            preload="none"
          />
        </video>
        <div className="btnContainer">
          <Link to="/Home">
            <button className="login__Button" type="submit">
              Enter
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
