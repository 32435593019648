import React from "react";
import "./Burger.css";
import { Link } from "react-router-dom";
export default function Burger() {
  let burgerHide = function () {
    var x = document.getElementById("Burger_Links");
    var y = document.getElementById("btnHamburger");
    if (x.style.display === "block") {
      y.classList.remove("T2");
      x.classList.remove("fade-in");
      x.classList.add("fade-out");
      x.style.display = "none";
    } else {
      y.classList.add("T2");
      x.classList.remove("fade-out");
      x.classList.add("fade-in");
      x.style.display = "block";
    }
  };
  let burgerTransform = function () {
    var x = document.getElementById("Burger_Links");
    var y = document.getElementById("btnHamburger");

    if (x.style.display === "block") {
      y.classList.remove("T2");
    } else {
      y.classList.add("T2");
    }
  };

  return (
    <div className="Burger">
      <div id="Burger_Links" className="Burger_Links">
        <nav>
          <Link to="/Home" style={{ textDecoration: "none" }}>
            <div className="header__option">
              <span className="nav_text">Home</span>
            </div>
          </Link>
          <Link to="/about" style={{ textDecoration: "none" }}>
            <div className="header__option">
              <span className="nav_text">About</span>
            </div>
          </Link>
          <Link to="/Projects" style={{ textDecoration: "none" }}>
            <div className="header__option">
              <span className="nav_text">Projects</span>
            </div>
          </Link>
          <Link to="/Skills" style={{ textDecoration: "none" }}>
            <div className="header__option">
              <span className="nav_text">Skills</span>
            </div>
          </Link>
          <Link to="/Contact" style={{ textDecoration: "none" }}>
            <div className="header__option">
              <span className="nav_text">Contact</span>
            </div>
          </Link>
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className="header__option">
              <span className="nav_text">Exit</span>
            </div>
          </Link>
        </nav>
      </div>
      <div className="Burger_nav">
        <a
          href="#"
          id="btnHamburger"
          className="header__menuToggle"
          onClick={burgerHide}
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
    </div>
  );
}
