import React from "react";
import "./About.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Console from "../Console/Console";
import Particles from "react-tsparticles";
import Slider from "react-slick";

export default function About() {
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="About">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.1,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#c00329",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.1,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 2000,
              },
              value: 100,
            },
            opacity: {
              value: 0.2,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
      <Header />
      <div className="About_Titles">
        <h1>Nahom Befekadu</h1>
      </div>
      <Console name="About" />
      <div className="About_Hero">
        <img
          className="About_Her_Img"
          src="../../../../Images/About.svg"
          alt="hero"
        />
      </div>
      <div className="About_Info">
        <Slider {...settings}>
          <div>
            <p>&lt; About className="Nahom" &gt;</p>
            <br></br>
            <p>
              I'm an aspiring Full Stack Developer and a Biomedical and
              Electrical Engineering Graduate
            </p>

            <p>
              In my free time, I work on side projects and learn about different
              types of framework. I'm currently working for the government as a
              technical advisor and studying computer programming at Algonquin.
              I'm looking forward to graduating and building more projects and
              contributing to my favorite Open-Source projects.
            </p>
            <p>&lt; /About &gt;</p>
          </div>
          <div>
            <p>&lt; About className="Social & Links" &gt;</p>
            <br></br>
            <p>
              Feel free to go through some of the projects and contact me using
              the links below
            </p>

            <div className="icon__container">
              <a
                href="https://github.com/NahomBefekadu"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="About__iconImg"
                  src="../../../../Images/git.svg"
                  alt=" Abstract Background"
                />
              </a>
              <a
                href="mailto:nbefkadu@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="About__iconImg"
                  src="../../../../Images/gmail.svg"
                  alt=" Abstract Background"
                />
              </a>
            </div>
            <p>&lt; /About &gt;</p>
          </div>
          <div>
            <p>&lt; About className="Education" &gt;</p>
            <br></br>
            <p>
              <strong>Carleton University</strong>
            </p>
            <p>Biomedical and Electrical Engineering</p>
            <br></br>
            <p>
              <strong>Algonquin College</strong>
            </p>
            <p>Computer Programming</p>
            <br></br>
            <p>&lt; /About &gt;</p>
          </div>
        </Slider>
      </div>
      <Footer />
    </div>
  );
}
