import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Burger from "./Burger/Burger";
export default function Header() {
  return (
    <div className="Header">
      <div className="Header_container">
        <div className="Header_Logo">
          <img className="logo" src="../../../../Images/NB.svg" alt="logo" />
        </div>
        <div className="Header_Nav">
          <div className="Header_Nav_container">
            <nav className="Main_Nav">
              <Link to="/Home" style={{ textDecoration: "none" }}>
                <div className="header__option">
                  <span className="nav_text">Home</span>
                </div>
              </Link>
              <Link to="/about" style={{ textDecoration: "none" }}>
                <div className="header__option">
                  <span className="nav_text">About</span>
                </div>
              </Link>
              <Link to="/Projects" style={{ textDecoration: "none" }}>
                <div className="header__option">
                  <span className="nav_text">Projects</span>
                </div>
              </Link>
              <Link to="/Skills" style={{ textDecoration: "none" }}>
                <div className="header__option">
                  <span className="nav_text">Skills</span>
                </div>
              </Link>
              <Link to="/Contact" style={{ textDecoration: "none" }}>
                <div className="header__option">
                  <span className="nav_text">Contact</span>
                </div>
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div className="header__option">
                  <span className="nav_text">Exit</span>
                </div>
              </Link>
            </nav>
          </div>
        </div>
        <div className="burgers">
          <Burger />
        </div>
      </div>
    </div>
  );
}
